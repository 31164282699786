import { formatDate } from "@movehq/datetime";
import { Box } from "@mui/material";
import classnames from "classnames";
import { Button, Footnote, HighlightUnbackedData, Panel } from "components";
import { CostSummary } from "components/pages/BelongingsPage/components/CostSummary";
import { useSurvey } from "components/pages/BelongingsPage/tools/surveyAtom";
import { useEstimatedMoveCost } from "hooks/useEstimatedOverageCost";
import { useSurveyCalculations } from "hooks/useSurveyCalculations";
import { useOrder } from "services/orders/OrderGuard";
import { FormattedMessage, useIntl } from "services/translation";
import scss from "./ReviewYourBelongingsPanel.module.scss";

export function ReviewYourBelongingsPanel() {
  const {
    hasSurvey,
    estimatedWeight,
    overage,
    hasOverage,
    estimatedWeightBreakDown,
  } = useSurveyCalculations();
  const survey = useSurvey();
  const { formatMessage } = useIntl();
  const order = useOrder();

  const { data } = useEstimatedMoveCost({
    estimatedWeightBreakDown: estimatedWeightBreakDown,
  });

  const hasPendingApproval = survey?.isPendingApproval;
  const overageElement = hasOverage ? (
    <div>
      <div>
        <span>
          <FormattedMessage id="reviewBelongings.overageWarning" />
        </span>
        <HighlightUnbackedData>
          <span
            className={classnames(scss.overage)}
            aria-label={formatMessage({ id: "ariaLabels.overageAmount" })}
          >{`${overage} lbs`}</span>
        </HighlightUnbackedData>
      </div>
    </div>
  ) : (
    <></>
  );

  const isVisible = hasSurvey;
  const scheduledDate = order.serviceScheduledDate() || "TBD";
  const alertContents = (
    <>
      <FormattedMessage id="reviewBelongings.adjustMessage" />
      <br></br>
      {order.status === "SURVEY_COMPLETED" && scheduledDate !== "TBD" && (
        <>
          Your move is scheduled to begin on
          {" " + formatDate(scheduledDate, "twoDigitDayAndMonth")}
        </>
      )}
    </>
  );

  return isVisible ? (
    <Panel
      icon="couch"
      title={<FormattedMessage id="reviewBelongings.title" />}
      ariaLabel={formatMessage({ id: "ariaLabels.reviewYourBelongingsPanel" })}
    >
      <div className={classnames(scss.estimation)}>
        <div aria-label={formatMessage({ id: "ariaLabels.surveySummary" })}>
          <div>
            <span>
              <FormattedMessage id="reviewBelongings.estimatedWeight" />
            </span>
            <HighlightUnbackedData>
              <span
                className={classnames(scss.weight)}
                aria-label={formatMessage({ id: "ariaLabels.weight" })}
              >{`${estimatedWeight} lbs`}</span>
              {hasPendingApproval ? <>*</> : null}
            </HighlightUnbackedData>
          </div>
          <Box mb="12px">{overageElement}</Box>
          {data && data.costEstimate.excessCost > 0 ? (
            <CostSummary mode="SURVEY" />
          ) : null}
        </div>
        {hasPendingApproval ? (
          <div>
            <Footnote>
              {" "}
              <FormattedMessage id="reviewBelongings.pendingChangesAlert" />
            </Footnote>
          </div>
        ) : null}
      </div>
      <BeginReviewButton />
      <Box mt="16px">{alertContents}</Box>
    </Panel>
  ) : null;
}

const BeginReviewButton = () => {
  const { formatMessage } = useIntl();

  return (
    <Button
      ariaLabel={formatMessage({ id: "ariaLabels.linkToReviewBelongings" })}
      to="/belongings"
      size="medium"
    >
      <FormattedMessage id="reviewBelongings.beginReview" />
    </Button>
  );
};
